import React, {useEffect, useMemo, useState} from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Button, Spinner, Table, Tabs, Toast } from "flowbite-react";

import { useNFT } from "../stores/nft";
import classNames from "classnames";
import {TokenBackpackCheckForm} from "../components"
import * as invitationSvc from "../services/invitation"
import { useInvitation } from "../stores/invitation";
import { shortenAddress } from "../utils/address";
import { generateMessage } from "../services/message";
import base58 from "bs58";


const NFTCard = ({nft, claimed, selectedNft, onSelected}) => (
  <div
    onClick={() => !claimed ? onSelected(nft) : undefined}
    className={classNames({
      "text-center p-2 rounded-md": true,
      "brightness-50": claimed,
      "transition hover:duration-100 hover:scale-105 cursor-pointer": !claimed,
      "bg-gray-900": selectedNft === nft,
    })}
  >
    <img src={nft.json.image} alt={nft.name} className="rounded-md" />
    <span className="dark:text-white">{nft.name}</span>
  </div>
)

const NFTList = ({nfts, claimedNfts, selectedNft, onSelected}) => (
  <div className="dark:bg-gray-800 py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10 w-full">
    <div className="space-y-4 w-full">
      <h2 className="dark:text-white text-xl">Select your Kaktees or Akiya to Claim</h2>
      <div className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full">
        {nfts.map((nft, index) => <NFTCard key={index} selectedNft={selectedNft} onSelected={onSelected} nft={nft} claimed={claimedNfts.includes(nft.mint.address.toString())} />)}
      </div>
    </div>
  </div>
)

export const RedeemCode = () => {
  const wallet = useWallet();
  const [isLoading, setLoading] = useState(false);
  const [elligibleMints, setElligibleMints] = useState([]);
  const [claimedCodes, setClaimedCodes] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const nfts = useNFT((state) => state.nfts);
  const fetchNfts = useNFT((state) => state.fetchNfts);

  const fetchProject = useInvitation((state) => state.fetchProject);
  const checkElligibleNfts = useInvitation((state) => state.checkElligibleNfts);

  const hashList = useInvitation((state) => state.hashList);
  const claimProject = useInvitation((state) => state.claimProject);

  const [selectedNft, setSelectedNft] = useState();

  useEffect(() => {
    fetchProject(1);
    invitationSvc.getSubmissions(1)
      .then((res) => setClaimedCodes(res))
      .catch((e) => console.log(e))
  }, [fetchProject]);

  useEffect(() => {
    if (wallet && wallet.publicKey) {
      setLoading(true)
      fetchNfts(wallet.publicKey, hashList)
        .catch((e) => console.log({e}))
        .finally(() => setLoading(false));
    }
  }, [wallet, fetchNfts, hashList]);

  useEffect(() => {
    if (nfts.length > 0) {
      checkElligibleNfts(1, nfts.map((v) => v.mint.address.toString()))
        .then((res) => setElligibleMints(res))
        .catch((e) => console.log(e))
    }
  }, [checkElligibleNfts, nfts])

  const elligibleNfts = useMemo(() => {
    return nfts.filter((v) => v.json && elligibleMints.includes(v.mint.address.toString()))
  }, [nfts, elligibleMints])

  const displayForm = useMemo(() => {
    return elligibleNfts.length > 0;
  }, [elligibleNfts]);

  const redeemCode = async () => {
    setLoading(true);
    setShowToast(false);
    const claimData = {
      nft: selectedNft.mint.address.toString(),
      address: wallet.publicKey.toString(),
    }
    try {
      const message = await generateMessage('code');
      const msg = new TextEncoder().encode(message);
      const signature = await wallet.signMessage(msg, "utf8")
      const tx = base58.encode(Uint8Array.from(signature));
      claimData.message = message;
      claimData.signature = tx;
      const res = await claimProject(1, claimData);
      setClaimedCodes((prev) => prev.concat([res]));
      setElligibleMints((prev) => prev.filter((v) => v !== claimData.nft));
      setSelectedNft(undefined);
      setShowToast(true);
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mx-auto">
      <div className="flex">
        <div className="w-full space-y-4">
          <Tabs.Group aria-label="Claim Tabs">
            <Tabs.Item active={true} title="Claim">
              {wallet.publicKey ? !displayForm ? (
                <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
                  <p className="dark:text-white text-lg">Backpack invite code claimed, pleace check Claimed Tab</p>
                  <div className="flex justify-center">
                    <Button href="https://www.xnft.gg/app/Afog7jAy3yuf24vCXvU9fMZsxsALp5kTJQ9jrYGjcCqd" className="mx-auto" target="_blank">
                      Download Nokiadex XNFT
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="w-full space-y-4">
                  {isLoading && (
                    <div className="flex justify-center">
                      <Spinner aria-label="Default status example" />
                    </div>
                  )}
                  <NFTList nfts={elligibleNfts} claimedNfts={[]} selectedNft={selectedNft} onSelected={setSelectedNft} />
                  <Button disabled={!selectedNft} onClick={() => redeemCode()}>
                    Redeem Code
                  </Button>
                </div>
              ) : null}
              {showToast && (
                <Toast className="my-2">
                  <div className="text-sm font-normal">
                    Successfull, please check Claimed Tab
                  </div>
                  <div className="ml-auto flex items-center space-x-2">
                    <Toast.Toggle />
                  </div>
                </Toast>
              )}
            </Tabs.Item>
            <Tabs.Item title="Claimed">
              <Table>
                <Table.Head>
                  <Table.HeadCell>Wallet Address</Table.HeadCell>
                  <Table.HeadCell>Token Address</Table.HeadCell>
                  <Table.HeadCell>Backpack Code</Table.HeadCell>
                  <Table.HeadCell>Claimed At</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {claimedCodes.map((v) => (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={v.id}>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {shortenAddress(v.claimed_address)}
                      </Table.Cell>
                      <Table.Cell>
                        {shortenAddress(v.claimed_nft)}
                      </Table.Cell>
                      <Table.Cell>
                        {v.code}
                      </Table.Cell>
                      <Table.Cell>
                        {v.claimed_at}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Tabs.Item>
            <Tabs.Item title="Token Check">
              <TokenBackpackCheckForm />
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </div>
    </div>
  )
}
