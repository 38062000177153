import React, { useMemo, useState } from "react";
import { Button, FileInput, Label, Spinner, Textarea, TextInput } from "flowbite-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { useProject } from "../stores/project";
import { generateMessage } from "../services/message";
import base58 from "bs58";
import { isAxiosError } from "axios";

export const ClaimForm = ({nft, elligible, isLoading, onCancel}) => {
  const wallet = useWallet();
  
  const [faceAccesories, setFaceAccesories] = useState('');
  const [skin, setSkin] = useState('');
  const [costumeAccesories, setCostumeAccesories] = useState('');
  const [headAccesories, setHeadAccesories] = useState('');
  const [file, setFile] = useState();
  const [bgColor, setBgColor] = useState('');

  const [name, setName] = useState('');
  const [face, setFace] = useState('');
  const [costume, setCostume] = useState('');
  const [head, setHead] = useState('');
  const [role, setRole] = useState('');

  const [isSubmit, setSubmit] = useState(false);
  const claimProject = useProject((state) => state.claimProject);

  const disabled = useMemo(() => {
    return !face || !faceAccesories || !name || !costume || !costumeAccesories || !head || !headAccesories || !bgColor || !file || !role || !skin;
  }, [
    face,
    faceAccesories,
    name,
    costume,
    costumeAccesories,
    head,
    headAccesories,
    bgColor,
    file,
    role,
    skin,
  ]);

  const [errors, setErrors] = useState({});

  const onSubmit = async () => {
    try {
      setErrors({});
      setSubmit(true);
      await new Promise(r => setTimeout(r, 1500));
      const claimData = {
        nft: nft.mint.address.toString(),
        address: wallet.publicKey.toString(),
        name,
        faceAccesories,
        face,
        skin,
        costumeAccesories,
        costume,
        headAccesories,
        head,
        file,
        bgColor,
        role,
      }
      const message = await generateMessage('ticket');
      const msg = new TextEncoder().encode(message);
      const signature = await wallet.signMessage(msg, "utf8")
      const tx = base58.encode(Uint8Array.from(signature));
      claimData.message = message;
      claimData.signature = tx;
      await claimProject(1, claimData);
      window.location.reload();
    } catch (e) {
      if (isAxiosError(e)) {
        setErrors(e.response.data);
      }
      console.log('invalid data');
    } finally {
      setSubmit(false);
    }
  }

  return (
    <div className="space-y-4">
      <div className="dark:bg-gray-800 py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10">
        <div className="space-y-4">
          <h2 className="dark:text-white text-xl">Fill in the following form</h2>
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="headAccesories"
                  value="First, what head accessories you want?"
                />
              </div>
              <Textarea
                id="headAccesories"
                disabled={isLoading || !elligible}
                required={true}
                value={headAccesories}
                color={errors.head_accesories && 'failure'}
                helperText={errors.head_accesories && (<React.Fragment>{ errors.head_accesories }</React.Fragment>)}
                row={4}
                onChange={(e) => setHeadAccesories(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="faceAccesories"
                  value="What about the face accessories?"
                />
              </div>
              <Textarea
                id="faceAccesories"
                disabled={isLoading || !elligible}
                required={true}
                value={faceAccesories}
                color={errors.face_accesories && 'failure'}
                helperText={errors.face_accesories && (<React.Fragment>{ errors.face_accesories }</React.Fragment>)}
                row={4}
                onChange={(e) => setFaceAccesories(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="skin"
                  value="What is the skin color or skin type?"
                />
              </div>
              <Textarea
                id="skin"
                disabled={isLoading || !elligible}
                required={true}
                value={skin}
                color={errors.skin && 'failure'}
                helperText={errors.skin && (<React.Fragment>{ errors.skin }</React.Fragment>)}
                row={4}
                onChange={(e) => setSkin(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="costumeAccesories"
                  value="Now, tell us about costume"
                />
              </div>
              <Textarea
                id="costumeAccesories"
                disabled={isLoading || !elligible}
                required={true}
                value={costumeAccesories}
                color={errors.costume_accesories && 'failure'}
                helperText={errors.costume_accesories && (<React.Fragment>{ errors.costume_accesories }</React.Fragment>)}
                row={4}
                onChange={(e) => setCostumeAccesories(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="bgColor"
                  value="And the background color."
                />
              </div>
              <Textarea
                id="bgColor"
                disabled={isLoading || !elligible}
                required={true}
                value={bgColor}
                color={errors.bg_color && 'failure'}
                helperText={errors.bg_color && (<React.Fragment>{ errors.bg_color }</React.Fragment>)}
                row={4}
                onChange={(e) => setBgColor(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="background"
                  value="Upload reference images here"
                />
              </div>
              <FileInput
                accept="image/png"
                id="background"
                onChange={(e) => setFile(e.target.files[0])}
                disabled={isLoading || !elligible}
                color={errors.bg && 'failure'}
                helperText={errors.bg && (<React.Fragment>{ errors.bg }</React.Fragment>)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dark:bg-gray-800 py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10">
        <div className="space-y-4">
          <h2 className="dark:text-white text-xl">Fill this for metadata</h2>
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="name"
                  value="Name"
                />
              </div>
              <TextInput
                id="name"
                type="text"
                disabled={isLoading || !elligible}
                required={true}
                value={name}
                color={errors.name && 'failure'}
                helperText={errors.name && (<React.Fragment>{ errors.name }</React.Fragment>)}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="face"
                  value="Face"
                />
              </div>
              <TextInput
                id="face"
                type="text"
                disabled={isLoading || !elligible}
                required={true}
                value={face}
                color={errors.face && 'failure'}
                helperText={errors.face && (<React.Fragment>{ errors.face }</React.Fragment>)}
                onChange={(e) => setFace(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="head"
                  value="Head Accessories Name"
                />
              </div>
              <TextInput
                id="head"
                type="text"
                disabled={isLoading || !elligible}
                required={true}
                value={head}
                color={errors.head && 'failure'}
                helperText={errors.head && (<React.Fragment>{ errors.head }</React.Fragment>)}
                onChange={(e) => setHead(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="costume"
                  value="Costume"
                />
              </div>
              <TextInput
                id="costume"
                type="text"
                disabled={isLoading || !elligible}
                required={true}
                value={costume}
                color={errors.costume && 'failure'}
                helperText={errors.costume && (<React.Fragment>{ errors.costume }</React.Fragment>)}
                onChange={(e) => setCostume(e.target.value)}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="role"
                  value="Role (Degen/Founder/Artist/Doctor or ect)"
                />
              </div>
              <TextInput
                id="role"
                type="text"
                disabled={isLoading || !elligible}
                required={true}
                value={role}
                color={errors.role && 'failure'}
                helperText={errors.role && (<React.Fragment>{ errors.role }</React.Fragment>)}
                onChange={(e) => setRole(e.target.value)}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <Button color='light' onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              color={isSubmit ? 'gray' : undefined}
              disabled={isLoading || !elligible || disabled}
              onClick={() => onSubmit()}>
              {isSubmit ? (
                <>
                  <Spinner />
                  <span className="pl-3">
                    Loading...
                  </span>
                </>
              ) : (
                <div className="flex items-center">
                  <ArrowRightCircleIcon className="w-4 h-4 mr-2" />
                  <span>Submit to Riz</span>
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
