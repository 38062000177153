import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import {useUser} from "../stores/user";
import { useNFT } from "../stores/nft";
import { useProject } from "../stores/project";
import { useMemo } from "react";

export const UserInfo = () => {
  const user = useUser((state) => state.user);
  const nfts = useNFT((state) => state.nfts);
  const claimedList = useProject((state) => state.claimedList);

  const tickets = useMemo(() => {
    return nfts.filter((v) => !claimedList.includes(v.mint.address.toString())).length;
  }, [nfts, claimedList]);

  if (!user) {
    return null;
  }

  return (
    <div className="dark:bg-gray-800 py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10 space-y-4">
      <div className="flex md:flex-row md:justify-between flex-col justify-center space-y-2 md:space-y-0">
        <span className="text-base md:text-3xl font-semibold dark:text-white mx-auto md:mx-0">
          Hi @{user.username}
        </span>
        <Link to="/claim" className="mx-auto md:mx-0">
          <Button className="md:w-48">Redeem Ticket</Button>
        </Link>
      </div>
      <div className="flex md:flex-row md:justify-between flex-col justify-center space-y-2 md:space-y-0 items-center">
        <span className="text-base md:text-2xl font-semibold dark:text-white">
          You have {tickets} tickets
        </span>
        <div className="md:space-y-4 flex md:flex-col mx-auto md:mx-0 md:space-x-0 space-x-2">
          <Button className="md:w-48" target="_blank" href="https://magiceden.io/marketplace/hooman_nft">Buy on ME</Button>
          <Button className="md:w-48" target="_blank" href="https://exchange.art/series/hooman/nfts">Buy on Exchange.Art</Button>
        </div>
      </div>
    </div>
  )
}
