import { Button } from "flowbite-react";

export const SubmitAlert = () => (
  <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
    <p className="dark:text-white text-lg">You have successfully redeem the ticket, LFG!</p>
    <div className="flex justify-center">
      <Button href="https://twitter.com/messages/compose?recipient_id=1457540057334571016&text=Redeem successfully" target="_blank">
        DM @flagmonkez
      </Button>
    </div>
  </div>
)
