import { PublicKey } from "@solana/web3.js";
import { Button, TextInput } from "flowbite-react";
import { useState } from "react";

export const SnapshotFormUpdateAuthority = (props) => {
  const [authority, setAuthority] = useState();

  const onSubmit = async () => {
    props.setLoading(true);
    const pk = new PublicKey(authority);
    const nfts = await props.mx.nfts().findAllByUpdateAuthority({
        updateAuthority: pk,
    });
    props.setOutput(nfts.filter((v) => v.model === 'metadata').map((v) => v.mintAddress.toString()));
    props.setLoading(false);
  }

  return (
    <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
      <TextInput
        id="mint"
        type="text"
        placeholder="6dex...."
        required={true}
        onChange={(e) => setAuthority(e.target.value)}
      />
      <Button onClick={() => onSubmit()}>
        Find
      </Button>
    </div>
  )
}
