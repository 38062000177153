import React, {useEffect, useState} from "react";
import { useWallet } from "@solana/wallet-adapter-react";

import { SubmissionTable } from "../components/SubmissionTable";
import { UserInfo } from "../components/UserInfo";
import { useNFT } from "../stores/nft";
import { useProject } from "../stores/project";
import { getUserSubmissions } from "../services/project";
import { Nokiamon } from "../components/Nokiamon";
import { AccountForm } from "../components/AccountForm";

export const Home = () => {
  const wallet = useWallet();

  const fetchNfts = useNFT((state) => state.fetchNfts);
  const fetchProject = useProject((state) => state.fetchProject);
  const hashList = useProject((state) => state.hashList);

  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    getUserSubmissions()
      .then((data) => setSubmissions(data))
      .catch((e) => console.log(e));
  }, [])


  useEffect(() => {
    fetchProject(1);
  }, [fetchProject]);

  useEffect(() => {
    if (wallet && wallet.publicKey) {
      fetchNfts(wallet.publicKey, hashList)
        .catch((e) => console.log({e}));
    }
  }, [wallet, fetchNfts, hashList]);

  return (
    <div className="mx-auto">
      <div className="justify-center space-y-6">
        <UserInfo />
        {/* <AccountForm user={{profile: {}}} discordLinked={false} twitterLinked={false} /> */}
        <div>
          <SubmissionTable submissions={submissions} />
        </div>
      </div>
    </div>
  )
}
