import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'flowbite';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Cookies from 'js-cookie';

Sentry.init({
  dsn: "https://b77b0c5404144ab2b3392eb93f06613e@o491294.ingest.sentry.io/4504580004052992",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

axios.defaults.baseURL = process.env.REACT_APP_API_HOST
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const csrfToken = Cookies.get('csrftoken');
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
