import React, { useEffect, useMemo, useState } from 'react';

import { HashRouter, Route, Routes } from 'react-router-dom';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';

import {MainLayout} from './layouts/MainLayout'
import * as views from './views'
import { useUser } from './stores/user';
import { AdminLayout } from './layouts/AdminLayout';

require('@solana/wallet-adapter-react-ui/styles.css');


function App() {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  const fetchCurrentUser = useUser((state) => state.fetchCurrentUser);
  const [isAuthenticated, setAuthenticated] = useState(true);
  const [isAdmin, setAdmin] = useState(true);

  useEffect(() => {
    fetchCurrentUser()
      .then((r) => {
        setAuthenticated(true);
        setAdmin(r.is_staff);
      })
      .catch(() => {
        setAuthenticated(false);
        setAdmin(false);
      })
  });

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <HashRouter>
            <Routes>
              <Route path={'/'} element={<MainLayout isAuthenticated={isAuthenticated} />}>
                <Route index element={<views.Home/>}/>
                <Route path={'/account'} element={<views.Account/>}/>
                <Route path={'/airdrop'} element={<views.Airdrop/>}/>
                <Route path={'/claim'} element={<views.Claim/>}/>
                <Route path={'/snapshot'} element={<views.Snapshot/>}/>
                <Route path={'/claim-backpack'} element={<views.RedeemCode/>}/>
                <Route path={'/mint'} element={<views.Mint/>}/>
                <Route path={'/admin'} element={<AdminLayout isAdmin={isAdmin} />}>
                  <Route index element={<views.Admin/>}/>
                </Route>
              </Route>
              <Route path={'/login'} element={<views.Login/>} />
            </Routes>
          </HashRouter>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
