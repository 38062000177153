import React, {useEffect, useState} from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Pagination } from "flowbite-react";

import { SubmissionTable } from "../components/SubmissionTable";
import { UserInfo } from "../components/UserInfo";
import { useNFT } from "../stores/nft";
import { useProject } from "../stores/project";
import { getSubmissions } from "../services/project";

export const Admin = () => {
  const [submissions, setSubmissions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    getSubmissions(page, 10, {})
      .then((r) => {
        setSubmissions(r.results);
        setTotalPage(Math.ceil(r.count / 10));
      })
      .catch(e => {
        console.log(e);
      })
  }, [page])

  return (
    <div className="mx-auto">
      <div className="justify-center space-y-6">
        <h1 className="text-2xl text-white">Admin</h1>
        <div>
          <SubmissionTable submissions={submissions} showUsername={true} />
          <Pagination
            currentPage={page}
            totalPages={totalPage}
            onPageChange={(n) => setPage(n)}
          />
        </div>
      </div>
    </div>
  )
}
