import React, {useEffect, useState} from "react";
import { Button, Spinner, Tabs, Textarea } from "flowbite-react";
import { Metaplex } from "@metaplex-foundation/js";
import { useWallet } from "@solana/wallet-adapter-react";
import { clusterApiUrl, Connection } from "@solana/web3.js";

import { SnapshotFormUpdateAuthority, SnapshotFormEdition } from "../components";

export const Snapshot = () => {
  const [output, setOutput] = useState();
  const [isLoading, setLoading] = useState(false);

  const connection = new Connection(
    process.env.REACT_APP_SOLANA_NETWORK ?? clusterApiUrl(process.env.REACT_APP_SOLANA_CLUSTER)
  );
  const mx = Metaplex.make(connection);

  return (
    <div className="mx-auto">
      <div className="justify-center space-y-6">
        {isLoading && (
          <div className="flex justify-center">
            <Spinner aria-label="Default status example" />
          </div>
        )}
        <Tabs.Group aria-label="snapshot tabs">
          <Tabs.Item active={true} title="Creator Address">
            <SnapshotFormUpdateAuthority mx={mx} setOutput={setOutput} setLoading={setLoading} />
          </Tabs.Item>
          <Tabs.Item title="Single Edition">
            <SnapshotFormEdition mx={mx} setOutput={setOutput} setLoading={setLoading} />
          </Tabs.Item>
        </Tabs.Group>
        <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
          <Textarea
            rows={10}
            disabled={isLoading || !output}
            value={output ? JSON.stringify(output) : ''}
          />
          <Button disabled={!output} onClick={() => navigator.clipboard.writeText(output)}>Copy</Button>
        </div>
      </div>
    </div>
  )
}
