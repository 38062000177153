import create from "zustand";
import { Metaplex } from "@metaplex-foundation/js";
import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";

const connection = new Connection(
  process.env.REACT_APP_SOLANA_NETWORK ?? clusterApiUrl(process.env.REACT_APP_SOLANA_CLUSTER)
);
const mx = Metaplex.make(connection);

export const useNokiamon = create((set) => ({
  nfts: [],
  fetchNfts: async (hashList = []) => {
    const nfts = await mx.nfts().findAllByMintList({ mints: hashList.map((v) => new PublicKey(v)) });
    const loadedNfts = await Promise.all(nfts.map((v) => mx.nfts().load({metadata: v})));

    // const pattern = /#(\d+)/;
    // const nftsWithNumber = nfts.map((v) => ({
    //     number: v.name, //.match(pattern)[1],
    //     nft: v,
    // }))
    // nftsWithNumber.sort(function(a, b) {
    //     var keyA = new Date(a.number), keyB = new Date(b.number);
    //     if (keyA < keyB) return -1;
    //     if (keyA > keyB) return 1;
    //     return 0;
    // });
    set({ nfts: loadedNfts });
    return loadedNfts
  },
  loadNft: async (metadata) => {
    const nft = await mx.nfts().load({ metadata });
    return nft
  },
}));
