import axios from "axios";

export const getProject = async (projectId) => {
  const { data } = await axios.get(`/api/project/invitation-project/${projectId}/`);
  return data;
}

export const getHashList = async (hashUrl) => {
  const { data } = await axios.get(hashUrl);
  return data;
}

export const claimProject = async (projectId, fd) => {
  const {data} = await axios.post(`/api/project/invitation-project/${projectId}/submit/`, fd)
  return data;
}

export const checkElligibleNfts = async (projectId, nfts) => {
  const {data} = await axios.post(`/api/project/invitation-project/${projectId}/elligible_nfts/`, {nfts})
  return data;
}

export const getSubmissions = async (projectId) => {
  const {data} = await axios.get(`/api/project/invitation-project/${projectId}/submission/`);
  return data;
}
