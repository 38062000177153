import axios from "axios";
import create from "zustand";

export const useUser = create((set) => ({
  user: null,
  setUser: (v) => set({ user: v }),
  fetchCurrentUser: async () => {
    const {data} = await axios.get('/api/auth/user/me');
    set({ user: data });
    return data;
  },
}));
