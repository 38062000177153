import React, { useState } from "react"
import { Badge, Button, Modal, Table } from "flowbite-react"
import { shortenAddress } from "../utils/address";
import { updateSubmissionStatus } from "../services/project";
import { useUser } from "../stores/user";

const DetailSubmission = ({allowUpdate, item, show, onClose}) => {
  if (!item) {
    return null;
  }

  const updateStatus = async (status) => {
    await updateSubmissionStatus(item.id, status);
    item.status = status;
    onClose();
  }

  const StatusButton = () => {
    if (item.status === 'todo') {
      return (
        <Button onClick={() => updateStatus('in_progress')}>
          Update Progress
        </Button>
      )
    } else if (item.status === 'in_progress') {
      return (
        <Button onClick={() => updateStatus('completed')}>
          Update Completed
        </Button>
      )
    }
  }

  return (
    <Modal
      show={show}
      size="4xl"
      position="center"
      onClose={onClose}
    >
      <Modal.Header>
        {item.name}
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="divide-y divide-gray-500">
            <div className="grid md:grid-cols-2 gap-4 py-2">
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Address</p>
                <p className="text-gray-500 dark:text-gray-300 text-sm">{item.address}</p>
              </div>
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">NFT</p>
                <p className="text-gray-500 dark:text-gray-300 text-sm">{item.nft}</p>
              </div>
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">First, what head accessories you want?</p>
                <p className="text-gray-500 dark:text-gray-300 text-sm">{item.head_accesories}</p>
              </div>
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">What about the face accessories?</p>
                <p className="text-gray-500 dark:text-gray-300 text-sm">{item.face_accesories}</p>
              </div>
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">What is the skin color or skin type?</p>
                <p className="text-gray-500 dark:text-gray-300 text-sm">{item.skin}</p>
              </div>
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Now, tell us about costume</p>
                <p className="text-gray-500 dark:text-gray-300 text-sm">{item.costume_accesories}</p>
              </div>
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">And the background color.</p>
                <p className="text-gray-500 dark:text-gray-300 text-sm">{item.bg_color}</p>
              </div>
              <div>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Image Reference</p>
                <a href={item.bg} target="_blank" rel="noreferrer" className="text-indigo-500 hover:text-indigo-700">
                  Download
                </a>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4 py-2">
              <div>
                  <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Name</p>
                  <p className="text-gray-500 dark:text-gray-300 text-sm">{item.name}</p>
                </div>
                <div>
                  <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Face</p>
                  <p className="text-gray-500 dark:text-gray-300 text-sm">{item.face}</p>
                </div>
                <div>
                  <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Head Accessories Name</p>
                  <p className="text-gray-500 dark:text-gray-300 text-sm">{item.head}</p>
                </div>
                <div>
                  <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Costume</p>
                  <p className="text-gray-500 dark:text-gray-300 text-sm">{item.costume}</p>
                </div>
                <div>
                  <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Role (Degen/Founder/Artist/Doctor or ect)</p>
                  <p className="text-gray-500 dark:text-gray-300 text-sm">{item.role}</p>
                </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {allowUpdate && <StatusButton />}
        <Button
          color="gray"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const SubmissionTable = ({submissions, showUsername = false}) => {
  const [show, setShow] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const user = useUser((state) => state.user);

  const openSubmission = (item) => {
    setCurrentItem(item);
    setShow(true);
  }

  return (
    <>
      <DetailSubmission show={show} item={currentItem} onClose={() => setShow(false)} allowUpdate={user?.is_staff} />
      <Table>
        <Table.Head>
          {showUsername && (
            <Table.HeadCell>
              Username
            </Table.HeadCell>
          )}
          <Table.HeadCell>
            NFT
          </Table.HeadCell>
          <Table.HeadCell>
            Wallet Address
          </Table.HeadCell>
          <Table.HeadCell>
            Status
          </Table.HeadCell>
          <Table.HeadCell>
            #
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {submissions.length === 0 && (
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell colSpan={7} className="text-center">
                No Submission
              </Table.Cell>
            </Table.Row>
          )}
          {submissions.map((item, index) => (
            <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
              {showUsername && (
                <Table.Cell>
                  {item.username}
                </Table.Cell>
              )}
              <Table.Cell>
                {shortenAddress(item.nft, 8)}
              </Table.Cell>
              <Table.Cell>
                {shortenAddress(item.address, 8)}
              </Table.Cell>
              <Table.Cell>
                <Badge size="sm" color={item.status === 'todo' ? "gray" : item.status === 'completed' ? 'success' : undefined}>
                  {item.status}
                </Badge>
              </Table.Cell>
              <Table.Cell>
                <Button size="xs" onClick={() => openSubmission(item)}>Detail</Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
