import { PublicKey } from "@solana/web3.js";
import { Button, TextInput } from "flowbite-react";
import { parseOriginalOrPrintEditionAccount, toNftEdition } from "@metaplex-foundation/js";
import { useState } from "react";

export const SnapshotFormEdition = (props) => {
  const [mint, setMint] = useState();

  const onSubmit = async () => {
    props.setLoading(true);
    const mintAddress = new PublicKey(mint);
    const nft = await props.mx.nfts().findByMint({
        mintAddress,
    });
    if (nft.edition.isOriginal) {
      console.log('retrieving all nft to be filtered as printed edition from the same authority:', nft.updateAuthorityAddress.toString());
      const nfts = await props.mx.nfts().findAllByUpdateAuthority({
          updateAuthority: nft.updateAuthorityAddress,
      });
      console.log(`found ${nfts.length} total of nfts, filtering...`);
      const duplicateNfts = nfts.filter((v) => v.symbol === nft.symbol && v.name === nft.name);
      const printedEditions = [];
      const chunkSize = 100;
      for (let i = 0; i < duplicateNfts.length; i += chunkSize) {
          const chunk = duplicateNfts.slice(i, i + chunkSize);
          const editionAddress = chunk.map((v) => props.mx.nfts().pdas().masterEdition({mint: v.mintAddress}));
          const editionAccounts = await props.mx.rpc().getMultipleAccounts(editionAddress);
          editionAccounts.forEach((editionAccount, i) => {
            if (!editionAccount.exists) {
              return;
            }
            const edition = toNftEdition(parseOriginalOrPrintEditionAccount(editionAccount));
            if (!edition.isOriginal && edition.parent.toString() === nft.edition.address.toString()) {
              printedEditions.push({name: chunk[i].name, mint: chunk[i].mintAddress.toString(), edition: edition.number.toNumber()});
            }
          });
      }
      console.log('found possible printed nfts', printedEditions.length);
      props.setOutput(printedEditions.map((v) => v.mint));
    } else if (!nft.edition.isOriginal) {
      console.log('parent nft:', nft.edition.parent.toString());
    }
    props.setLoading(false);
  }

  return (
    <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
      <TextInput
        id="mint"
        type="text"
        placeholder="6dex...."
        required={true}
        onChange={(e) => setMint(e.target.value)}
      />
      <Button onClick={() => onSubmit()}>
        Find
      </Button>
    </div>
  )
}
