import create from "zustand";
import * as invitationSvc from "../services/invitation"

export const useInvitation = create((set, get) => ({
  project: undefined,
  hashList: [],
  fetchProject: async (projectId) => {
    const project = await invitationSvc.getProject(projectId);
    const hashList = await invitationSvc.getHashList(project.hash_list);

    set({
      project,
      hashList,
    });
    return project;
  },
  checkElligibleNfts: async (projectId, nfts) => {
    const data = await invitationSvc.checkElligibleNfts(projectId, nfts)
    return data;
  },
  claimProject: async (projectId, claimData) => {
    const res = await invitationSvc.claimProject(projectId, claimData);
    return res;
  },
}));
