import React, { useMemo, useState } from "react";
import bs58 from 'bs58';
import { Button, Card, Checkbox, Label, Spinner } from "flowbite-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { useProject } from "../stores/project";
import { generateMessage } from '../services/message';
import classNames from "classnames";


export const AirdropForm = ({nft, elligible, isLoading, onCancel}) => {
  const wallet = useWallet();

  const [character, setCharacter] = useState('');
  const [agree, setAgree] = useState(false);

  const [isSubmit, setSubmit] = useState(false);
  const claimAirdropProject = useProject((state) => state.claimAirdropProject);

  const disabled = useMemo(() => {
    return !character || !agree;
  }, [
    agree,
    character
  ]);

  const onSubmit = async () => {
    try {
      setSubmit(true);
      await new Promise(r => setTimeout(r, 1500));
      const claimData = {
        nft: nft.mint.address.toString(),
        address: wallet.publicKey.toString(),
        character,
      }
      const message = await generateMessage('airdrop');
      const msg = new TextEncoder().encode(message);
      const signature = await wallet.signMessage(msg, "utf8")
      const tx = bs58.encode(Uint8Array.from(signature));
      claimData.message = message;
      claimData.signature = tx;
      await claimAirdropProject(1, claimData);
      window.location.reload();
    } catch (e) {
      console.log('invalid data');
    } finally {
      setSubmit(false);
    }
  }

  return (
    <div className="space-y-4">
      <div className="dark:bg-gray-800 py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10">
        <div className="space-y-4">
          <h2 className="dark:text-white text-xl">Select Your Nokiamon Airdrop</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 gap-4">
            <Card
              onClick={() => setCharacter('pluggy')}
              className={classNames({
                "transition hover:duration-100 hover:scale-105 cursor-pointer": true,
                "bg-gray-900 pb-2 ring ring-indigo-500": character === 'pluggy',
              })}
              imgAlt="pluggy"
              imgSrc={require('../assets/nokiamon/electric.png')}
            >
              <h5 className="text-lg lg:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Pluggy
                <span className="bg-[#B1B500] text-white text-sm font-normal px-2 py-0.5 rounded dark:bg-[#B1B500] dark:text-white ml-2">
                  Electric
                </span>
              </h5>
              <p className="text-sm md:text-base font-normal text-gray-700 dark:text-gray-400">
                Pluggy is fun electric nokiamon, don't make it angry or it will shock you
              </p>
            </Card>
            <Card
              onClick={() => setCharacter('rindi')}
              className={classNames({
                "transition hover:duration-100 hover:scale-105 cursor-pointer": true,
                "bg-gray-900 pb-2 ring ring-indigo-500": character === 'rindi',
              })}
              imgAlt="rindi"
              imgSrc={require('../assets/nokiamon/dark.png')}
            >
              <h5 className="text-lg lg:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Rindi
                <span className="bg-[#3300C2] text-white text-sm font-normal px-2 py-0.5 rounded dark:bg-[#3300C2] dark:text-white ml-2">
                  Dark
                </span>
              </h5>
              <p className="text-sm md:text-base font-normal text-gray-700 dark:text-gray-400">
                Rindi is lonely and bravest nokiamon, it can walk and see in the dark night without using the eyes
              </p>
            </Card>
            <Card
              onClick={() => setCharacter('althea')}
              className={classNames({
                "transition hover:duration-100 hover:scale-105 cursor-pointer": true,
                "bg-gray-900 pb-2 ring ring-indigo-500": character === 'althea',
              })}
              imgAlt="althea"
              imgSrc={require('../assets/nokiamon/physic.png')}
            >
              <h5 className="text-lg lg:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Althea
                <span className="bg-[#2D80CC] text-white text-sm font-normal px-2 py-0.5 rounded dark:bg-[#2D80CC] dark:text-white ml-2">
                  Physic
                </span>
              </h5>
              <p className="text-sm md:text-base font-normal text-gray-700 dark:text-gray-400">
                Althea have power to heal itself, It can leap hooman when the necklace on it's neck shining
              </p>
            </Card>
          </div>
        </div>
      </div>
      <div className="dark:bg-gray-800 py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10">
        <div className="flex items-center mb-4 space-x-4">
          <Checkbox id="agree-checbox" checked={agree} onChange={() => setAgree((prev) => !prev)} />
          <Label htmlFor="agree-checbox" className="text-2xl">
          I agree that this is my final choice of nokiamon
          </Label>
        </div>
        <div className="flex justify-end space-x-2">
          <Button color='light' onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            color={isSubmit ? 'gray' : undefined}
            disabled={isLoading || !elligible || disabled}
            onClick={() => onSubmit()}>
            {isSubmit ? (
              <>
                <Spinner />
                <span className="pl-3">
                  Loading...
                </span>
              </>
            ) : (
              <div className="flex items-center">
                <ArrowRightCircleIcon className="w-4 h-4 mr-2" />
                <span>Submit to Riz</span>
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
