import create from "zustand";
import { Metaplex } from "@metaplex-foundation/js";
import { clusterApiUrl, Connection } from "@solana/web3.js";

const connection = new Connection(
  process.env.REACT_APP_SOLANA_NETWORK ?? clusterApiUrl(process.env.REACT_APP_SOLANA_CLUSTER)
);
const mx = Metaplex.make(connection);

export const useNFT = create((set) => ({
  nfts: [],
  fetchNfts: async (owner, hashList = []) => {
    const metadatas = await mx.nfts().findAllByOwner({ owner });
    console.log({hashList, nfts: metadatas.map((m) => m.mintAddress.toString())});
    const nfts = await Promise.all(metadatas.filter((v) => hashList.includes(v.mintAddress.toString())).map((metadata) => mx.nfts().load({metadata})));
    set({ nfts });
    console.log(`fetch ${nfts.length}/${metadatas.length}`);
    return nfts;
  },
}));
