import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {SidebarMenu} from "../components";
import { NavbarMenu } from '../components/NavbarMenu';

export const MainLayout = (props) => {
  if (!props.isAuthenticated) {
    return <Navigate to="/login" />
  }

  return (
    <div className="dark:bg-gray-900">
      <NavbarMenu />
      <div className="relative md:flex h-screen overflow-hidden">
        <SidebarMenu />
        <div className="w-full p-4 md:p-8 h-screen overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
