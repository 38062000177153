import { Metadata, Metaplex, toMetaplexFileFromBrowser, walletAdapterIdentity } from "@metaplex-foundation/js";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Button, FileInput, Label, Textarea, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";

export const MintForm = (props) => {
  const [name, setName] = useState('Carbon Code');
  const [symbol, setSymbol] = useState('CC');
  const [description, setDescription] = useState();
  const [rolayties, setRoyalties] = useState(100);
  const [creators, setCreators] = useState([]);
  const [attributes, setAttributes] = useState([{trait_type: '', value: ''}]);
  const [file, setFile] = useState(null)

  const wallet = useWallet()

  useEffect(() => {
    if (wallet && wallet.publicKey) {
      setCreators([{address: wallet.publicKey, share: 100}])
    }
  }, [wallet])

  const updateCreatorAddress = (index, address) => {
    setCreators((prev) => {
        prev[index].address = new PublicKey(address)
        return prev
    })
  }

  const updateCreatorShare = (index, value) => {
    setCreators((prev) => {
      prev[index].share = value
      return prev
  })
  }

  const updateAttribute = (index, key, value) => {
    setAttributes((prev) => {
      if (key === 'trait_type' || key === 'value') {
        prev[index][key] = value
      }
      return prev
    })
  }

  const onSubmit = async () => {
    const {uri, metadata} = await props.mx.use(walletAdapterIdentity(wallet)).nfts().uploadMetadata({
      name,
      symbol,
      description,
      sellerFeeBasisPoints: rolayties,
      creators,
      attributes,
      image: file ? await toMetaplexFileFromBrowser(file) : undefined,
    })
    const { nft } = await props.mx.use(walletAdapterIdentity(wallet)).nfts().create({
      uri,
      name,
      sellerFeeBasisPoints: rolayties,
    });
    console.log({nft, metadata})
  }

  return (
    <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
      <div className="grid grid-cols-2 gap-2">
        <div id="fileUpload" className="col-span-2">
          <div className="mb-2 block">
            <Label
              htmlFor="image"
              value="NFT Image"
            />
          </div>
          <FileInput
            id="image"
            accept="image/*"
            helperText="(png, jpg, gif)"
            onChange={(e) => setFile(e.target.files && e.target.files[0])}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="name"
              value="NFT Name"
            />
          </div>
          <TextInput
            id="name"
            type="text"
            placeholder="Nokiamon #1"
            required={true}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="symbol"
              value="NFT Symbol"
            />
          </div>
          <TextInput
            id="symbol"
            type="text"
            placeholder="NKM"
          />
        </div>
        <div className="col-span-2">
          <div className="mb-2 block">
            <Label
              htmlFor="description"
              value="Description (optional)"
            />
          </div>
          <Textarea
            id="description"
            rows={3}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="rolayties"
              value="Royalties"
            />
          </div>
          <TextInput
            id="rolayties"
            type="number"
            required={true}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="creators"
              value="Creators"
            />
          </div>
          <div>
            {creators.map((creator, index) => (
              <div className="flex justify-between space-x-2 mb-2" key={index}>
                <TextInput id={`creator-address-${index}`} type="text" className="flex-1" onChange={(e) => updateCreatorAddress(index, e.target.value)} defaultValue={creator.address?.toString()} />
                <TextInput id={`creator-share-${index}`} min="0" max="100" type="number" onChange={(e) => updateCreatorShare(index, parseFloat(e.target.value))} defaultValue={creator.share} />
              </div>
            ))}
            <Button color="gray" size="xs" onClick={() => setCreators((prev) => prev.concat([{}])) }>Add Creator</Button>
          </div>
        </div>
        <div className="col-span-2">
          <div className="mb-2 block">
            <Label
              htmlFor="attributes"
              value="Attributes"
            />
          </div>
          <div>
            {attributes.map((attribute, index) => (
              <div className="flex justify-between space-x-2 mb-2" key={index}>
                <TextInput placeholder="Type" id={`attribute-${index}`} type="text" defaultValue={attribute.trait_type} className="w-full" onChange={(e) => updateAttribute(index, 'type', e.target.value)} />
                <TextInput placeholder="Value" id={`attribute-${index}`} type="text" defaultValue={attribute.value} className="w-full" onChange={(e) => updateAttribute(index, 'value', e.target.value)} />
              </div>
            ))}
            <Button color="gray" size="xs" onClick={() => setAttributes((prev) => prev.concat([{trait_type: '', value: ''}])) }>Add Attribute</Button>
          </div>
        </div>
      </div>
      <Button onClick={() => onSubmit()}>
        Mint
      </Button>
    </div>
  )
}
