import React from "react"
import { Avatar, Dropdown, Navbar } from "flowbite-react"

import {useUser} from "../stores/user";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export const NavbarMenu = () => {
  const user = useUser((state) => state.user);

  return (
    <Navbar
      className="border-b-2 border-white"
      fluid={true}
      rounded={false}
    >
      <Navbar.Brand href="/">
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Hooman
        </span>
      </Navbar.Brand>
      <div className="flex space-x-4 md:order-2">
        <div className="hidden sm:block">
          <WalletMultiButton />
        </div>
        <Dropdown
          arrowIcon={false}
          inline={true}
          label={<Avatar rounded={true} />}
        >
          <Dropdown.Header>
            {user && <span className="block text-sm">{user.username}</span>}
          </Dropdown.Header>
          <Dropdown.Item onClick={() => window.location.href="/api/auth/user/logout"}>
            Sign out
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse className="md:hidden">
        <Navbar.Link
          href="/"
          active={true}
        >
          Dashboard
        </Navbar.Link>
        <Navbar.Link
          href="/#/airdrop"
        >
          Airdrop
        </Navbar.Link>
				<Navbar.Link
          href="/#/claim-backpack"
          active={false}
        >
          Backpack Claim
        </Navbar.Link>
        <Navbar.Link
          href="/#/snapshot"
          active={false}
        >
          Snapshot
        </Navbar.Link>
        {user && user.is_staff && (
          <>
            <Navbar.Link
              href="/#/admin"
              active={false}
            >
              Admin
            </Navbar.Link>
          </>
        )}
        <div className="my-4 justify-center flex sm:hidden">
          <WalletMultiButton />
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}
