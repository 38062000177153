import axios from "axios";

export const getProject = async (projectId) => {
  const { data } = await axios.get(`/api/project/project/${projectId}/`);
  return data;
}

export const getHashList = async (hashUrl) => {
  const { data } = await axios.get(hashUrl);
  return data;
}

export const claimProject = async (projectId, fd) => {
  const {data} = await axios.post(`/api/project/project/${projectId}/submit/`, fd)
  return data;
}

export const claimAirdropProject = async (projectId, fd) => {
  try {
    const {data} = await axios.post(`/api/project/project/${projectId}/submit_airdrop/`, fd)
    return data;
  } catch (e) {
    console.log(e)
  }
}

export const getUserSubmissions = async () => {
  const {data} = await axios.get('/api/project/user-submission/');
  return data;
}

export const getSubmissions = async (page, limit, filters) => {
  const {data} = await axios.get('/api/project/submission/', {
    ...filters,
    page,
    page_size: limit,
  });
  return data;
}

export const updateSubmissionStatus = async (submissionId, status) => {
  const {data} = await axios.patch(`/api/project/submission/${submissionId}/update_status/`, {status});
  return data;
}
