import React, { useMemo } from 'react';
import { Sidebar } from 'flowbite-react';
import * as Icons from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import { useUser } from '../stores/user';

export const SidebarMenu = (props) => {
  const user = useUser((state) => state.user);

  const menuItems = useMemo(() => {
    const items = [
      { title: "Dashboard", link: "/", name: "Dashboard", icon: Icons.HomeIcon },
      { title: "Airdrop", link: "/airdrop", name: "Airdrop", icon: Icons.PaperAirplaneIcon },
      { title: "Nokiamon", link: "/", name: "Nokiamon", icon: Icons.TableCellsIcon, children: [
        { title: "Claim Backpack", link: "/claim-backpack", name: "Claim Backpack", icon: Icons.QrCodeIcon },
      ] },
      { title: "Tools", link: "/", name: "Tools", icon: Icons.WrenchIcon, children: [
        // { title: "Mint", link: "/mint", name: "Mint", icon: Icons.GifIcon },
        { title: "Snapshot", link: "/snapshot", name: "Snapshot", icon: Icons.CameraIcon }
      ] },
    ];
    if (user && user.is_staff) {
      items.push({ title: "Admin", link: "/admin", name: "Admin", icon: Icons.BuildingLibraryIcon });
    }
    return items;
  }, [user]);

  return (
    <div className="hidden md:flex">
      <Sidebar aria-label="Sidebar">
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {menuItems.map((menu, index) => !menu.children ? (
              <Sidebar.Item
                as={Link}
                key={`menu-${index}`}
                to={menu.link}
                icon={menu.icon}
              >
                {menu.title}
              </Sidebar.Item>
            ) : (
              <Sidebar.Collapse icon={menu.icon} label={menu.title} key={`menu-${index}`}>
                {menu.children.map((child, childIndex) => (
                  <Sidebar.Item
                    as={Link}
                    key={`sub-${childIndex}`}
                    to={child.link}
                    icon={child.icon}
                  >
                    {child.title}
                  </Sidebar.Item>
                ))}
              </Sidebar.Collapse>
            ) )}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  )
}
