import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {SidebarMenu} from "../components";
import { NavbarMenu } from '../components/NavbarMenu';

export const AdminLayout = (props) => {
  if (!props.isAdmin) {
    return <Navigate to="/login" />
  }

  return (
    <Outlet />
  )
}
