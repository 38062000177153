import React from "react";

export const Login = () => {
  const loginUrl = `${process.env.REACT_APP_API_HOST}/oauth2/login/twitter/`;

  return (
    <div className="flex h-screen items-center dark:bg-gray-900">
      <div className="mx-auto text-center dark:bg-gray-800 shadow p-8 px-16 space-y-8 rounded">
        <h1 className="text-5xl dark:text-white font-bold">Hooman</h1>
        <div className="flex justify-center">
          <a
            href={loginUrl}
            className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-700"
          >
            Login with Twitter
          </a>
        </div>
      </div>
    </div>
  )
}
