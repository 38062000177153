import React, {useEffect, useState} from "react";
import { Button, Spinner, Tabs, Textarea } from "flowbite-react";
import { bundlrStorage, Metaplex } from "@metaplex-foundation/js";
import { useWallet } from "@solana/wallet-adapter-react";
import { clusterApiUrl, Connection } from "@solana/web3.js";

import { MintForm } from "../components";

export const Mint = () => {
  const [output, setOutput] = useState();
  const [isLoading, setLoading] = useState(false);

  const connection = new Connection(
    process.env.REACT_APP_SOLANA_NETWORK ?? clusterApiUrl(process.env.REACT_APP_SOLANA_CLUSTER)
  );
  const mx = Metaplex
    .make(connection)
    .use(bundlrStorage({
      address: 'https://devnet.bundlr.network',
      providerUrl: 'https://api.devnet.solana.com',
      timeout: 60000,
    }));

  return (
    <div className="mx-auto">
      <div className="justify-center space-y-6">
        {isLoading && (
          <div className="flex justify-center">
            <Spinner aria-label="Default status example" />
          </div>
        )}
        <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
          <MintForm mx={mx} />
        </div>
      </div>
    </div>
  )
}
