import React, {useEffect, useMemo, useState} from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Spinner } from "flowbite-react";

import { AirdropForm, SubmitAlert } from "../components";
import { useNFT } from "../stores/nft";
import classNames from "classnames";
import { useProject } from "../stores/project";


const NFTCard = ({nft, claimed, selectedNft, onSelected}) => (
  <div
    onClick={() => !claimed ? onSelected(nft) : undefined}
    className={classNames({
      "text-center p-2 rounded-md": true,
      "brightness-50": claimed,
      "transition hover:duration-100 hover:scale-105 cursor-pointer": !claimed,
      "bg-gray-900": selectedNft === nft,
    })}
  >
    <img src={nft.json.image} alt={nft.name} className="rounded-md" />
    <span className="dark:text-white">{nft.name}</span>
  </div>
)

const NFTList = ({nfts, claimedNfts, selectedNft, onSelected}) => (
  <div className="dark:bg-gray-800 py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10 w-full">
    <div className="space-y-4 w-full">
      <h2 className="dark:text-white text-xl">Select your Hooman or Flag Monkez</h2>
      <div className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full">
        {nfts.map((nft, index) => <NFTCard key={index} selectedNft={selectedNft} onSelected={onSelected} nft={nft} claimed={claimedNfts.includes(nft.mint.address.toString())} />)}
      </div>
    </div>
  </div>
)

export const Airdrop = () => {
  const wallet = useWallet();
  const [isLoading, setLoading] = useState(false);

  const nfts = useNFT((state) => state.nfts);
  const fetchNfts = useNFT((state) => state.fetchNfts);
  const fetchProject = useProject((state) => state.fetchProject);
  const hashList = useProject((state) => state.airdropHashList);
  const airdroppedList = useProject((state) => state.airdroppedList);

  const [selectedNft, setSelectedNft] = useState();

  const elligibleNfts = useMemo(() => {
    if (!airdroppedList) {
      return [];
    }
    return nfts.filter((v) => v.json && !v.json.name.includes('Commission') && !airdroppedList.includes(v.mint.address.toString()));
  }, [nfts, airdroppedList]);

  useEffect(() => {
    fetchProject(1)
  }, [fetchProject]);

  useEffect(() => {
    if (wallet && wallet.publicKey) {
      fetchNfts(wallet.publicKey, hashList)
        .catch((e) => console.log({e}))
        .finally(() => setLoading(false));
    }
  }, [wallet, fetchNfts, hashList]);

  const hasClaimed = useMemo(() => {
    return nfts.length > 0 && elligibleNfts.length === 0 && airdroppedList.length > 0;
  }, [airdroppedList, elligibleNfts, nfts]);

  return (
    <div className="mx-auto">
      <div className="flex">
        <div className="w-full space-y-4">
          {wallet.publicKey ? hasClaimed ? (
            <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
              <p className="dark:text-white text-lg">
                You have successfully choose your nokiamon, LFG!
              </p>
            </div>
          ) : (
            <div className="w-full space-y-4">
              {isLoading && (
                <div className="flex justify-center">
                  <Spinner aria-label="Default status example" />
                </div>
              )}
              {nfts.length > 0 && <NFTList nfts={elligibleNfts} claimedNfts={[]} selectedNft={selectedNft} onSelected={setSelectedNft} />}
              {selectedNft && <AirdropForm elligible={elligibleNfts.length > 0} isLoading={isLoading} nft={selectedNft} onCancel={() => setSelectedNft(null)} />}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
