import { Button, TextInput, Toast } from "flowbite-react";
import { useState } from "react";
import * as Icons from '@heroicons/react/24/outline'
import * as invitationSvc from '../services/invitation';
import classNames from "classnames";

export const TokenBackpackCheckForm = () => {
  const [token, setToken] = useState();
  const [showToast, setShowToast] = useState(false);
  const [isError, setError] = useState(false);

  const onSubmit = async () => {
    setError(false);
    setShowToast(false);
    try {
      const res = await invitationSvc.checkElligibleNfts(1, [token]);
      setShowToast(true);
      setError(res.length === 0);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div className="dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6 text-center">
        <TextInput
          id="mint"
          type="text"
          placeholder="6dex...."
          required={true}
          onChange={(e) => setToken(e.target.value)}
        />
        <Button onClick={() => onSubmit()}>
          Check Token
        </Button>
      </div>
      {showToast && (
        <Toast className="my-2">
          <div className={classNames({
            "bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200": isError,
            "bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200": !isError,
            "inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg": true
          })}>
            {isError ? <Icons.XMarkIcon className="h-5 w-5" /> : <Icons.CheckIcon className="h-5 w-5" />}
          </div>
          <div className="ml-3 text-sm font-normal">
            {isError ? 'Token not elligible' : 'Token elligible'}
          </div>
          <Toast.Toggle />
        </Toast>
      )}
    </>
  )
}
