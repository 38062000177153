import create from "zustand";
import {
  claimProject,
  getProject,
  getHashList,
  claimAirdropProject,
} from "../services/project"

export const useProject = create((set) => ({
  project: undefined,
  hashList: [],
  airdropHashList: [],
  claimedList: [],
  airdroppedList: [],
  fetchProject: async (projectId) => {
    const project = await getProject(projectId);
    const hashList = await getHashList(project.hash_list);
    let airdropHashList = [];
    if (project.airdrop_hash_list) {
      airdropHashList = hashList.concat(await getHashList(project.airdrop_hash_list));
    } else {
      airdropHashList = hashList;
    }

    set({
      project,
      hashList,
      airdropHashList,
      claimedList: project.claimed,
      airdroppedList: project.airdropped,
    });
    return project;
  },
  claimProject: async (projectId, claimData) => {
    const fd = new FormData();
    fd.append('address', claimData.address);
    fd.append('nft', claimData.nft);
    fd.append('name', claimData.name);
    fd.append('face_accesories', claimData.faceAccesories);
    fd.append('face', claimData.face);
    fd.append('skin', claimData.skin);
    fd.append('costume_accesories', claimData.costumeAccesories);
    fd.append('costume', claimData.costume);
    fd.append('head_accesories', claimData.headAccesories);
    fd.append('head', claimData.head);
    fd.append('bg', claimData.file);
    fd.append('bg_color', claimData.bgColor);
    fd.append('role', claimData.role);
    fd.append('message', claimData.message);
    fd.append('signature', claimData.signature);
    await claimProject(projectId, fd);
  },
  claimAirdropProject: async (projectId, claimData) => {
    await claimAirdropProject(projectId, claimData);
  },
}));
